<template>
  <common-form
    name="insurance"
    @submit="submitData"
  >
    <pp-frame>
      <template #message>
        <h2 class="blue-grey--text">
          <p>{{ $t('Medical Insurance') }}</p>
        </h2>

        <div class="secondary--text">
          {{ translate(`
          Please complete all fields on this page to allow us to verify your
          medical insurance coverage.
          `) }}<br>
          {{ translate(`
          Failure to accurately complete these fields may result in insurance
          delays and even denial of coverage.
          `) }}<br>
          {{ translate(`
          Refer to your medical insurance card for this information
          `) }}
        </div>
      </template>

      <v-row class="flex-grow-0">
        <v-col
          class="d-flex align-enter"
          cols="12"
        >
          <h3 class="d-inline blue-grey--text toggle__tittle">
            {{ $t('Do you have medical insurance?') }}
          </h3>

          <common-toggle
            v-model="info.hasPMIns"
            class="ml-3"
          />
        </v-col>

        <v-col cols="12">
          1. {{ $t('Do you have Medicare?') }}
          <common-toggle
            v-model="info.pMInsType"
            class="ml-3"
            :disabled="!info.hasPMIns"
          />
        </v-col>
        <v-subheader>
          {{ translate(`
          Please complete the information below for the PRIMARY INSURED PARTY
          listed on your insurance card.
          `) }}
        </v-subheader>
        <v-col cols="12">
          <v-select
            v-model="info.pMRelation"
            :items="relationItems"
            :label="`2. ${pMRelationLabel}:`"
            :rules="!info.hasPMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="info.pMPartyfname"
            :label="`3. ${pMPartyfnameLabel}:`"
            :rules="!info.hasPMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="info.pMPartymname"
            :label="`4. ${pMPartymnameLabel}:`"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="info.pMPartylname"
            :label="`5. ${pMPartylnameLabel}:`"
            :rules="!info.hasPMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <vuetify-date-picker
            v-model="info.insDob"
            :label="`6a. ${insDobLabel}:`"
            :rules="!info.hasPMIns ? [] : [
              $validators.isValid(),
              $validators.isValidDate()
            ]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="info.pMGender"
            :items="genderItems"
            :label="`6b. ${pMGenderLabel}`"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="insContact.company"
            :label="`7a. ${companyLabel}`"
            :rules="!info.hasPMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="insContact.address1"
            :label="`7b. ${address1Label}`"
            :rules="!info.hasPMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="insContact.address2"
            :label="`7c. ${address2Label}`"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="insContact.city"
            :label="`7d. ${cityLabel}`"
            :rules="!info.hasPMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="insContact.state"
            :label="`7e. ${stateLabel}`"
            :items="states"
            :rules="!info.hasPMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="insContact.zip"
            v-mask="'#####-####'"
            :label="`7f. ${zipLabel}`"
            autocomplete="off"
            :disabled="!info.hasPMIns"
            :rules="!info.hasPMIns ? [] : [
              $validators.isValid(),
              $validators.isZipCode()
            ]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="insContact.phone"
            v-mask="'(###) ###-####'"
            :label="`7g. ${phoneLabel}`"
            autocomplete="off"
            :rules="!info.hasPMIns ? [] : [
              $validators.isValid(),
              $validators.isValidPhone()
            ]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="insContact.fax"
            v-mask="'(###) ###-####'"
            :label="`7h. ${faxLabel}`"
            autocomplete="off"
            :rules="[!insContact.fax ||
              $validators.isValidFax()]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="insContact.email"
            :label="`7i. ${emailLabel}`"
            :rules="!info.hasPMIns ? [] : [
              !insContact.email ||
                $validators.isEmail()
            ]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.pMInsId"
            :label="`8. ${pMInsIdLabel}`"
            :rules="!info.hasPMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.pMInsGrp"
            :label="`9. ${pMInsGrpLabel}`"
            :rules="!info.hasPMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.pMInsPlan"
            :label="`10. ${pMInsPlanLabel}`"
            :rules="!info.hasPMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasPMIns"
            dense
          />
        </v-col>
      </v-row>

      <template #actions>
        <v-row class="ma-0">
          <v-btn to="/register/personal-info">
            <v-icon>mdi-chevron-double-left</v-icon>
            {{ $t('Back') }}
          </v-btn>

          <v-spacer />

          <v-btn
            color="primary"
            type="submit"
            :loading="updatingProfile"
          >
            {{ $t('Proceed') }}
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </pp-frame>
  </common-form>
</template>

<script>
import { ref, watch, onMounted, computed } from '@vue/composition-api'
import moment from 'moment'

import { utils, validators, events } from 'ui-common'
import { useProfile } from '@/use/profile'
import {
  states,
  PageInsurance,
  activeMedicareInsType,
  activeMedicareInsSubType
} from '@/constants'
import { useQuestionnaire } from '@/use/questionnaire'

const useInsurance = () => {
  const info = ref({
    hasPMIns: false,
    pMInsType: false,
    pMRelation: '',
    pMPartyfname: '',
    pMPartymname: '',
    pMPartylname: '',
    insDob: null,
    pMInsId: '',
    pMInsGrp: '',
    pMInsPlan: ''
  })

  const insContact = ref({
    company: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    fax: '',
    email: ''
  })

  const {
    secondaryProfile,

    primaryInsuranceContacts,

    updatingProfile,
    updateSecondaryProfile
  } = useProfile()

  const {
    getCompletedSections
  } = useQuestionnaire()

  const feedData = () => {
    const {
      hasPMIns,
      pMInsType,
      pMRelation = '',
      pMPartyfname = '',
      pMPartymname = '',
      pMPartylname = '',
      insDob,
      pMGender = '',
      pMInsId = '',
      pMInsGrp = '',
      pMInsPlan = ''
    } = secondaryProfile.value

    const {
      company,
      address1,
      address2,
      city,
      state,
      zip,
      phone,
      fax,
      email
    } = primaryInsuranceContacts.value

    info.value = {
      hasPMIns: hasPMIns === 'Yes',
      pMInsType: !!pMInsType,
      pMRelation,
      pMPartyfname,
      pMPartymname,
      pMPartylname,
      insDob: insDob && moment(insDob).format('MM/DD/YYYY'),
      pMGender,
      pMInsId,
      pMInsGrp,
      pMInsPlan
    }

    insContact.value = {
      company,
      address1,
      address2,
      city,
      state,
      zip,
      phone: validators.formatPhoneNumber(phone),
      fax: validators.formatPhoneNumber(fax),
      email
    }
  }

  const submitData = async () => {
    try {
      const { hasPMIns } = info.value

      if (!hasPMIns) {
        await updateSecondaryProfile({
          info: {
            hasPMIns: 'No',
            lastRegSect: PageInsurance
          }
        })
      } else {
        const {
          pMInsType,
          insDob
        } = info.value

        const {
          company = '',
          address1 = '',
          address2 = '',
          city = '',
          state = '',
          zip = '',
          phone = '',
          fax = '',
          email = ''
        } = insContact.value

        await updateSecondaryProfile({
          info: {
            ...info.value,
            hasPMIns: 'Yes',
            pMInsType: pMInsType
              ? (hasPMIns ? activeMedicareInsType : activeMedicareInsSubType)
              : '',
            insDob: insDob && moment(insDob).format('MM/DD/YYYY'),
            lastRegSect: PageInsurance
          },
          insContact: {
            type: 1,
            company,
            address1,
            address2,
            city,
            state,
            zip,
            phone: validators.formatPhoneNumber(phone, false),
            fax: validators.formatPhoneNumber(fax, false),
            email
          }
        })
      }
      await getCompletedSections()
      utils.navigate('/register')
    } catch (err) { console.log(err) }
  }

  onMounted(() => {
    watch(() => info.pMRelation, (value) => {
      if (value !== 'Self') { return }

      const {
        firstname,
        middlename,
        lastname,
        dob,
        gender,

        add1,
        add2,
        city,
        state,
        zip,
        email
      } = secondaryProfile.value

      info.value.pMPartyfname = firstname
      info.value.pMPartymname = middlename
      info.value.pMPartylname = lastname
      info.value.insDob = dob
      info.value.pMGender = gender

      info.value.address1 = add1
      info.value.address2 = add2
      info.value.city = city
      info.value.state = state
      info.value.zip = zip
      info.value.email = email
    })
  })

  return {
    info,
    insContact,
    feedData,
    submitData,
    updatingProfile
  }
}

export default {
  setup: (props, context) => {
    const { root } = context

    const relationItems = [
      {
        text: 'Self',
        value: 'Self'
      },
      {
        text: 'Spouse',
        value: 'Spouse'
      },
      {
        text: 'Child',
        value: 'Child'
      },
      {
        text: 'Other',
        value: 'Other'
      }
    ]
    const genderItems = [
      {
        text: 'Male',
        value: 'Male'
      },
      {
        text: 'Female',
        value: 'Female'
      }
    ]

    const {
      info,
      insContact,
      feedData,
      submitData,
      updatingProfile
    } = useInsurance()

    onMounted(() => {
      feedData()
      root.$nextTick(() => {
        events.emit('form.reset.insurance')
      })
    })

    return {
      info,
      insContact,
      states,
      submitData,
      updatingProfile,

      relationItems: computed(() =>
        relationItems.map(item => {
          item.text = root.$i18n.t(item.value)
          return item
        })
      ),
      genderItems: computed(() =>
        genderItems.map(item => {
          item.text = root.$i18n.t(item.value)
          return item
        })
      ),

      pMRelationLabel: computed(() =>
        root.$i18n.t('Your relationship to primary insured')
      ),
      pMPartyfnameLabel: computed(() =>
        root.$i18n.t('Insured First Name')
      ),
      pMPartymnameLabel: computed(() =>
        root.$i18n.t('MI')
      ),
      pMPartylnameLabel: computed(() =>
        root.$i18n.t('Insured Last Name')
      ),
      insDobLabel: computed(() =>
        root.$i18n.t('Insured Date of Birth')
      ),
      pMGenderLabel: computed(() =>
        root.$i18n.t('Insured Gender')
      ),
      companyLabel: computed(() =>
        root.$i18n.t('Insurance Company')
      ),
      address1Label: computed(() =>
        root.$i18n.t('Address 1')
      ),
      address2Label: computed(() =>
        root.$i18n.t('Address 2')
      ),
      cityLabel: computed(() =>
        root.$i18n.t('City')
      ),
      stateLabel: computed(() =>
        root.$i18n.t('State')
      ),
      zipLabel: computed(() =>
        root.$i18n.t('Zip')
      ),
      phoneLabel: computed(() =>
        root.$i18n.t('Phone')
      ),
      faxLabel: computed(() =>
        root.$i18n.t('Fax')
      ),
      emailLabel: computed(() =>
        root.$i18n.t('Email')
      ),
      pMInsIdLabel: computed(() =>
        root.$i18n.t('Insurance ID')
      ),
      pMInsGrpLabel: computed(() =>
        root.$i18n.t('Group #')
      ),
      pMInsPlanLabel: computed(() =>
        root.$i18n.t('Plan Name')
      )
    }
  }
}
</script>

<style>
.toggle__tittle {
  line-height: 40px;
}
</style>
